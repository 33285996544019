import React, {useEffect} from 'react';
import Router from "Router";

function App() {
    useEffect(() => {
        if(window.location.href.includes('?n') || window.location.href.includes('?r') ){
            sessionStorage.setItem('isn', 'true');
        }
    }, []);
    return <>
        <Router/>
    </>
}

export default App;
